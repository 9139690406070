export const Syllabus_mtech = [
    {
        "BRANCH" : "CHEMICAL ENGINEERING",
        "Filename": "CHE R23 SYLLABUS",
        "location": "NA"
    }, {
        "BRANCH" : "CIVIL ENGINEERING",
        "Filename": "CE R23 SYLLABUS",
        "location": "NA"
    }, {
        "BRANCH" : "ELECTRICAL & ELECTRONICS ENGINEERING",
        "Filename": "EEE R23 SYLLABUS",
        "location": "NA"
    }, {
        "BRANCH" : "ELECTRONICS AND COMMUNICATION ENGINEERING",
        "Filename": "ECE R23 SYLLABUS",
        "location": "NA"
    }, {
        "BRANCH" : "MECHANICAL ENGINEERING",
        "Filename": "ME R23 SYLLABUS",
        "location": "NA"
    }, {
        "BRANCH" : "COMPUTER SCIENCE AND ENGINEERING",
        "Filename": "CSE R23 SYLLABUS",
        "location" :"NA"
    },
    // {
    //     "BRANCH" : "COMPUTER SCIENCE AND ENGINEERING",
    //     "Filename": "CSE R23 SYLLABUS",
    //     "location" :"/academics/SVUCE_MTech_CSE_R2018_Syllabus.pdf"
    // }
    
]