import { Container,Row,Col, Card, CardBody, CardTitle, CardSubtitle, Table} from "reactstrap"

const calender = () =>{
     return(
<Container className="timetable">
    <Row>   
                        <p>Click here to Download Academic Calendar for 2023-2024- <a href="/academics/academic schedule.pdf">click</a></p>
    </Row>

</Container>
     ) 
}
export default calender