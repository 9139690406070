import { Container,Row,Col, Card, CardBody, CardTitle, CardSubtitle, Table} from "reactstrap"

const timetables = () =>{
     return(
<Container className="timetable">
    <Row>
                 <p>Click here to Download Timetables for this Acedemic Year - <a href="/academics/B. Tech I Sem Time Table.pdf">click</a></p>
    </Row>

</Container>
     ) 
}
export default timetables