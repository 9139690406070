
import { Col, Container, Table, Row } from "reactstrap";
const viceprincipal = () => {
  return (
    <Container>
      <Row>
        <h2 style={{ color: "orange", marginLeft: 100 }}>SRI VENKATESWARA UNIVERSITY COLLEGE OF ENGINEERING</h2>
      </Row>
      <Row>
        <Col>
          <div style={{ marginLeft: 100 }}><img style={{ width: 250, height: 250}} src="	https://svuniversity.edu.in/storage/2022/02/Dr-Ch-D-V-Subba-Rao.png" />
            <p><b>Dr. Ch.D.V. Subba Rao</b></p></div>
        </Col>
        <Col>
          <div style={{ marginTop: 100 }}>
            <h2 style={{ color: '' }}>Dr. Ch.D.V. Subba Rao</h2>
            <h4>Professor of C.S.E </h4>
            {/* <h4>Ph.D</h4> */}
            <h4><span>Email - </span>subbarao_chdv@hotmail.co
            </h4>
            <h6>For Details  <a href="/cseFiles/Ch_D_V_Subba_Rao.pdf">Click Here</a></h6>

          </div>

        </Col>

      </Row>
      <Row>
        <p style={{ marginTop: 50 }}>
          Prof Ch D V Subba Rao entered into S V University service as Assistant Professor in CSE in the year 1992 and promoted as Associate Professor in 2003 and subsequently as Professor on January 1, 2009. Earlier he worked as a faculty member in REC Warangal (Presently NIT Warangal) during 1991-92. Prof Rao conferred with PhD (CSE) from SVU and it is the first PhD in CSE from the Universities and Deemed Universities located in Rayalaseema. His areas of interests include Distributed Systems, Wireless Networks, Data Engineering and Cloud Computing. At present, Prof Rao is serving as the Head, Dept of CSE, S V University, Tirupati and Chairman, Board of Studies in CSE, School of Engineering and Technology, Sri Padmavathi Women’s University (SPMVV), Tirupati
          <br />
          <br />
          <br />
          Prof Rao is a Fellow of IETE, NewDelhi, Fellow of IE (India) Kolkata and Senior Member of Computer Society of India, NewDelhi. He published more than 78 papers in International Journals. His publications appear in IEEE, ACM, Elsevier and Springer digital libraries. He guided 7 PhD theses and 41 MTech theses. He published 3 Text Books and 2 Patents. At present he is supervising the work of 7 PhD scholars. Prof Rao visited Austria, Hong-Kong, Netherlands, Belgium, Thailand, Germany and Singapore on academic work. He chaired the Sessions of International Conferences viz. IEEE, IAENG, IASTED held in India, Austria, Hong-Kong respectively.
          <br />
          <br />
          <br />
          Prof Subba Rao served as Head, Dept of CSE, S V University for two terms and Officer-in-Charge of Computer Centre, SVUCE.  He served as the Coordinator, MSIT Program offered at SVUCE in collaboration with CMU, USA. He is serving as Member, Board of Studies in CSE of Bharatiar University/Coimbatore, Dravidian University and number of Autonomous Engg. Colleges. Earlier, he served as the member, Board of Studies of IIIT/Nuzvid, Krishna University and Vikrama Simhapuri University. Prof Rao has been handling GATE coaching classes to the CSE students of SVUCE since 2000 (an honorary assignment).
          <br />
          <br />
          He has been serving as External Expert in the Departmental Promotion Committees (DPC) for promoting Scientists/Engineers of ISRO from level SE to level SF. He has been serving as the Member of AICTE Inspection Committees and Subject Expert for recruiting/ ratifying faculty members including CAS promotional committees. He served as a member in the state level steering committees of ECET, ICET and PGECET constituted by APSCHE.  Prof Subba Rao served as one of the Project Investigators of Centre of Excellence (Interdisciplinary) of worth Rs 5.0 Crores established under TEQIP Phase II in SVUCE. Prof Rao served as IT Consultant for three major projects of Govt of AP that includes Tirupati Smart-City Project.
          <br /><br /><br />
          Prof Subba Rao received the prestigious “Ugadi Puraskaram cum DTA Excellence Award’ from Delhi Telugu Academy (DTA) from Deputy Chief Minister of AP in 2015. Prof Rao received ‘Rashtriya Gaurav Award’ from Former Governor of TN and Assam at a function held in Constitution Club of India, New Delhi in the year 2011. He received Adarsh Vidya Saraswati Rashtriya Puraskar from Global Management Council, Ahmedabad in 2023. He received Best Paper Award for his technical paper in the IAENG International Conference held in Hong-Kong in the year 2008.
        </p>
      </Row>

    </Container>
  )
}
export default viceprincipal;

